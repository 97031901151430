import type { Config } from "tailwindcss";

const config: Config = {
  content: ["./src/app/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    extend: {
      fontFamily: {
        sans: ["var(--font-garet)"],
        display: ["var(--font-garet)"],
        accent: ["var(--font-museo)"],
      },
      fontSize: {
        sm: ["0.875rem", "1.375rem"], // 14px font size with 22px line-height
        "5xl": ["2.5rem", "2.625rem"], // 40px font size with 42px line-height
        "6xl": ["3rem", "1"], // 48px font size
        "7xl": ["3.25rem", "1"], // 52px font size
        "8xl": ["3.75rem", "1"], // 60px font size
        "9xl": ["4.5rem", "1"], // 72px font size
        "10xl": ["6rem", "1"], // 96px font size
        "11xl": ["8rem", "1"], // 128px font size
      },
      lineHeight: {
        "3xl": "38px",
      },
      colors: {
        dark: "#262626",
        red: {
          "50": "#FDE9EA",
          "100": "#FBD3D4",
          "200": "#F7A7A9",
          "300": "#F37C7F",
          "400": "#EF5054",
          "500": "#EB2429",
          "600": "#BC1D21",
          "700": "#8D1619",
          "800": "#5E0E10",
          "900": "#2F0708",
          "950": "#180404",
        },
        crimsonred: {
          "50": "#F8E8E8",
          "100": "#F0D1D2",
          "200": "#E1A3A5",
          "300": "#D37578",
          "400": "#C4474B",
          "500": "#B5191E",
          "600": "#911418",
          "700": "#6D0F12",
          "800": "#480A0C",
          "900": "#240506",
          "950": "#120303",
        },
        brickred: {
          "50": "#F2E7E8",
          "100": "#E5D0D0",
          "200": "#CBA1A2",
          "300": "#B27273",
          "400": "#974345",
          "500": "#7E1416",
          "600": "#651012",
          "700": "#4C0C0D",
          "800": "#32080A",
          "900": "#190403",
          "950": "#0D0202",
        },
        //slateblue
        teal: {
          "50": "#F3F8FA",
          "100": "#E8F1F5",
          "200": "#D0E2EA",
          "300": "#B9D4E0",
          "400": "#A1C5D5",
          "500": "#8AB7CB",
          "600": "#6E92A2",
          "700": "#536E7A",
          "800": "#374A51",
          "900": "#1C2529",
          "950": "#0E1214",
        },
        skyblue: {
          "50": "#F8FBFD",
          "100": "#F1F8FA",
          "200": "#E2F0F6",
          "300": "#D5E9F1",
          "400": "#C7E1ED",
          "500": "#B9DAE8",
          "600": "#94AEBA",
          "700": "#6F838B",
          "800": "#4B575D",
          "900": "#252C2E",
          "950": "#131617",
        },
        //royalpurple
        plum: {
          "50": "#EEECF5",
          "100": "#DED9EA",
          "200": "#BCB2D6",
          "300": "#9B8CC1",
          "400": "#7965AD",
          "500": "#583F98",
          "600": "#46327A",
          "700": "#35265B",
          "800": "#23193D",
          "900": "#120D1E",
          "950": "#09060F",
        },
        lightlavender: {
          "50": "#F7F5FA",
          "100": "#EFEBF4",
          "200": "#E0D7E9",
          "300": "#D0C2DF",
          "400": "#C1AED4",
          "500": "#B19AC9",
          "600": "#8E7BA1",
          "700": "#6A5C79",
          "800": "#473E50",
          "900": "#231F28",
          "950": "#120F14",
        },
        //radiantorange
        gold: {
          "50": "#FEF6E8",
          "100": "#FEEDD1",
          "200": "#FDDBA3",
          "300": "#FCCA76",
          "400": "#FBB848",
          "500": "#FAA61A",
          "600": "#C88515",
          "700": "#966410",
          "800": "#64420A",
          "900": "#322105",
          "950": "#191103",
        },
        yellowsunny: {
          "50": "#FFFBE5",
          "100": "#FFF6CC",
          "200": "#FFEE99",
          "300": "#FFE566",
          "400": "#FFDD33",
          "500": "#FFD400",
          "600": "#CCAA00",
          "700": "#997F00",
          "800": "#665500",
          "900": "#332A00",
          "950": "#1A1500",
        },
        //grassgreen
        green: {
          "50": "#F5FAEB",
          "100": "#EBF4D8",
          "200": "#D7EAB1",
          "300": "#C2DF8A",
          "400": "#AED563",
          "500": "#9ACA3C",
          "600": "#7BA230",
          "700": "#5C7924",
          "800": "#3E5118",
          "900": "#1F280C",
          "950": "#0F1406",
          tint: "#DFE67D",
        },
        //springgreen
        lime: {
          "50": "#F9FCF0",
          "100": "#F4F8E1",
          "200": "#E9F1C3",
          "300": "#DEEBA5",
          "400": "#D3E487",
          "500": "#C8DD69",
          "600": "#A0B154",
          "700": "#78853F",
          "800": "#50582A",
          "900": "#282C15",
          "950": "#14160B",
        },
        //midnightblue
        blue: {
          "50": "#E8EAEF",
          "100": "#D3D5DF",
          "200": "#A5ACBF",
          "300": "#78829F",
          "400": "#4B597F",
          "500": "#1E2F5F",
          "600": "#18264C",
          "700": "#121C39",
          "800": "#0C1326",
          "900": "#060913",
          "950": "#03050A",
          tint: "#4878BB",
          "tint-dark": "#476dae",
          link: "#3D73AE",
        },
        darkblue: {
          100: "#B4BABF",
          200: "#8FA0B3",
          300: "#6A85A6",
          400: "#496B99",
          500: "#2D518C",
          600: "#143980",
          700: "#0A2359",
          800: "#031133",
          900: "#00030D",
        },
        neutral: {
          100: "#FAFAFA",
          200: "#F5F5F5",
          300: "#F0F0F0",
          400: "#D9D9D9",
          500: "#BFBFBF",
          600: "#8C8C8C",
          700: "#595959",
          800: "#262626",
          900: "#000",
        },
        marketing: {
          lightgray: {
            "50": "#FBFAFA",
            "100": "#F7F6F5",
            "200": "#EFECEC",
            "300": "#E6E3E2",
            "400": "#DED9D9",
            "500": "#D6D0CF",
            "600": "#ABA6A6",
            "700": "#807D7C",
            "800": "#565353",
            "900": "#2B2A29",
            "950": "#151515",
          },
          darkgray: {
            "50": "#F6F5F5",
            "100": "#ECEBEB",
            "200": "#D9D7D7",
            "300": "#C7C4C3",
            "400": "#B4B0AF",
            "500": "#A19C9B",
            "600": "#817D7C",
            "700": "#615E5D",
            "800": "#403E3E",
            "900": "#201F1F",
            "950": "#101010",
          },
        },
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        "scale-ease-out-elastic": {
          "0%": { opacity: "0", transform: "scale(0)" },
          "50%": { opacity: "1", transform: "scale(1.25)" },
          "65%": { transform: "scale(0.85)" },
          "75%": { transform: "scale(1.1)" },
          "85%": { transform: "scale(0.95)" },
          "90%": { transform: "scale(1.02)" },
          "100%": { transform: "scale(1)" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "scale-in-elastic": "scale-ease-out-elastic 0.7s ease-out",
      },
      screens: {
        xs: "391px",
      },
      spacing: {
        "7.5": "1.875rem", // 30px
      },
    },
  },
  plugins: [require("tailwindcss-animate")],
};

export default config;
