"use client";
import React, { useEffect } from "react";
import HomeStatBox from "./HomeStatBox";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../reducers/store";
import { fetchVegWideStats } from "../../reducers/vegWideStatsSlice";

export type StatRecord = {
  count: number;
  name: string;
  isUser?: boolean;
};

type StatsInput = {
  [key: string]: StatRecord;
};

function transformStats(stats: StatsInput): Array<StatRecord> {
  return Object.keys(stats).map((key) => {
    const { name, count } = stats[key];
    return {
      name: `${name.split(" ")[0]} ${name.charAt(name.indexOf(" ") + 1)}.`,
      id: key,
      count,
    };
  });
}

function mergeAndLimitStats(
  mainStats: StatsInput,
  userStat: StatRecord,
  limit: number = 8,
): Array<StatRecord> {
  const transformedMainStats = transformStats(mainStats)
    .sort((a, b) => b.count - a.count)
    .slice(0, limit);

  console.log("mergeAndLimitStats", { mainStats });

  // Check if a stat with the same first name and count already exists
  const existingUserStatIndex = transformedMainStats.findIndex(
    (stat) => stat.name === userStat.name,
  );

  if (existingUserStatIndex === -1) {
    // Add the user's stat if not already existing to the end of the array
    transformedMainStats[transformedMainStats.length - 1] = {
      ...userStat,
      isUser: true,
    };
  } else {
    // Update the existing record if a match is found
    transformedMainStats[existingUserStatIndex].isUser = true;
  }

  // Sort the array by count in descending order and slice to limit the size to `limit`
  return transformedMainStats;
}

const HomeStats = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userReadArticles = useSelector(
    (state: RootState) => state.userReadArticles,
  );
  const { userStreakStats } = useSelector(
    (state: RootState) => state.userStreakStats,
  );
  const vegWideStats = useSelector((state: RootState) => state.vegStats);
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchVegWideStats(accessToken));
    }
  }, [dispatch, accessToken]);

  if (
    vegWideStats.isLoading ||
    !userStreakStats ||
    !userReadArticles.userInfo
  ) {
    return null;
  }

  const name = `${userReadArticles.firstName} ${userReadArticles.lastName.charAt(0)}.`;

  const userStreaks = {
    perfectHuddler: {
      name,
      count: userStreakStats.badges.perfect_huddler.year_to_date,
    },
    huddleStarter: {
      name,
      count: userStreakStats.huddles_started,
    },
    bookworm: {
      name,
      count: userStreakStats.badges.bookworm.year_to_date,
    },
  };

  const perfectHuddlers = mergeAndLimitStats(
    vegWideStats.vegWideStats.perfectHuddler,
    userStreaks.perfectHuddler,
  );

  const huddleStarters = mergeAndLimitStats(
    vegWideStats.vegWideStats.huddleStarter,
    userStreaks.huddleStarter,
  );

  const bookworms = mergeAndLimitStats(
    vegWideStats.vegWideStats.bookworm,
    userStreaks.bookworm,
  );

  return (
    <div id="homeStats" className="px-10 pb-10">
      <h2 className="font-display font-bold uppercase text-2xl sm:text-4xl pb-5">
        VEG-WIDE Stats
      </h2>
      <div className="flex flex-col gap-5 lg:grid lg:grid-cols-3">
        <HomeStatBox
          title="Top Perfect Huddlers"
          subtitle="huddle 3 times a week"
          color="blue"
          stats={perfectHuddlers}
        />
        <HomeStatBox
          title="Top huddle starters"
          subtitle="huddles started this year"
          color="red"
          stats={huddleStarters}
        />
        <HomeStatBox
          title="daily article whiz"
          subtitle="read 3 daily articles a week"
          color="plum"
          stats={bookworms}
        />
      </div>
    </div>
  );
};

export default HomeStats;
