"use client";
import React from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import { RootState } from "@/app/_components/reducers/store";
import { IMAGES_BUCKET, PLACEHOLDER_HUDDLE_IMG } from "@/app/_consts/internal";
import ImageWithFallback from "../../ui/media/ImageWithFallback";
import PawIcon from "../../ui/graphics/PawIcon";
import HomeHuddleButtons from "../../ui/huddle/HomeHuddleButtons";
import { useUserHuddleStreakCount } from "@/app/_utils/huddle";
import { useNewHuddleFlow } from "../../hooks/useNewHuddleFlow";
import { cn } from "@/app/_utils/ui";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../../tailwind.config";

const HomeHuddle = () => {
  const themeConfig = resolveConfig(tailwindConfig);
  const dailyArticle = useSelector((state: RootState) => state.dailyArticle);
  const userHuddles = useSelector((state: RootState) => state.userHuddles);
  const userHuddleStreakCount = useUserHuddleStreakCount(userHuddles.huddles);

  const imageUrl = dailyArticle.huddleImagePath
    ? `${IMAGES_BUCKET}/USERS/HUDDLE/${dailyArticle.huddleImagePath}`
    : undefined;

  const isNewHuddleFlow = useNewHuddleFlow();

  const Paw = ({ isFilled }: { isFilled: boolean }) => (
    <PawIcon
      fill={isFilled ? "#fff" : themeConfig.theme?.colors?.red?.[900]}
      className={cn(
        "paw-icon w-[45px] h-[42px] transition-all ease-out duration-500",
        !isNewHuddleFlow && "md:w-[28px] md:h-[25px]",
      )}
    />
  );

  return (
    <div
      id="homeHuddle"
      className="grid lg:grid-cols-2 p-0 lg:p-10 bg-red-600 text-white"
    >
      <div className="min-h-64 lg:pr-4">
        <div className="relative w-full h-full overflow-hidden md:rounded-2xl">
          <Link href="/photos">
            {imageUrl && (
              <ImageWithFallback
                src={imageUrl}
                alt="Daily Huddle Image"
                fallback={PLACEHOLDER_HUDDLE_IMG}
              />
            )}
          </Link>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div
          className={cn(
            "flex flex-col py-8 md:py-16 items-center  justify-center relative z-10",
            isNewHuddleFlow ? "md:items-center" : "md:items-start",
          )}
        >
          <h2 className="text-center font-display uppercase font-bold text-4xl sm:text-7xl">
            It’s Huddle Time
          </h2>
          <div className="flex flex-col gap-4 md:gap-2 items-center md:items-start pt-4 pb-6 md:pb-0 font-bold text-lg sm:text-2xl">
            <div className="text-center text-balance">
              {userHuddleStreakCount > 0
                ? "You’re on a doggone streak!"
                : "Almost there! Huddle to earn a streak!"}
            </div>
            <div
              className={cn(
                "flex gap-2",
                isNewHuddleFlow && "w-full justify-center",
              )}
            >
              <Paw isFilled={userHuddleStreakCount > 0} />
              <Paw isFilled={userHuddleStreakCount > 1} />
              <Paw isFilled={userHuddleStreakCount > 2} />
            </div>
          </div>
          <div className="py-4">
            <HomeHuddleButtons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHuddle;
