"use client";
import React from "react";
import { StatRecord } from "./HomeStats";
import { cn } from "@/app/_utils/ui";

const HomeStatBox = ({
  title,
  subtitle,
  color,
  stats,
}: {
  title: string;
  subtitle: string;
  color: "blue" | "red" | "plum";
  stats: StatRecord[];
}) => {
  const bgColors = {
    blue: ["bg-blue-600", "bg-blue-500", "bg-blue-400"],
    red: ["bg-crimsonred-600", "bg-crimsonred-500", "bg-crimsonred-400"],
    plum: ["bg-plum-600", "bg-plum-500", "bg-plum-400"],
  };
  const textColors = {
    blue: ["text-white", "text-dark", "text-dark"],
    red: ["text-white", "text-dark", "text-dark"],
    plum: ["text-white", "text-dark", "text-dark"],
  };

  return (
    <div>
      <h3 className="font-accent font-medium lowercase text-xl sm:text-2xl">
        {title}
      </h3>
      <p className="text-sm mb-4">{subtitle}</p>
      {stats.map((stat, index) => {
        let widthClass = "w-full";
        let bgClass = bgColors[color][0];
        let textClass = textColors[color][0];

        if (index === 1) {
          widthClass = "w-3/4";
          bgClass = bgColors[color][1];
        } else if (index === 2) {
          widthClass = "w-1/2";
          bgClass = bgColors[color][2];
        } else if (index > 2) {
          widthClass = "w-1/2";
          bgClass = "";
          textClass = "";
        }

        return (
          <div
            key={index}
            className={cn(
              "flex items-center justify-between py-1 pl-9 pr-2 rounded-r-full relative",
              index < 3 && "mb-2",
              index > 2 && "font-light",
              widthClass,
              bgClass,
              textClass,
              stat.isUser && "font-bold",
            )}
          >
            {index < 3 && (
              <span className="absolute top-[5px] left-1.5 font-display font-bold">
                #{index + 1}
              </span>
            )}
            <span className="relative top-px line-clamp-1">{stat.name}</span>
            <span className="relative top-px">{stat.count}</span>
          </div>
        );
      })}
    </div>
  );
};

export default HomeStatBox;
