import React from "react";

interface HomeHeaderBadgeProps {
  imgSrc: string;
  imgAlt: string;
  badgeCount: number | undefined;
  badgeLabel: string;
}

const HomeHeaderBadge: React.FC<HomeHeaderBadgeProps> = ({
  imgSrc,
  imgAlt,
  badgeCount,
  badgeLabel,
}) => {
  return (
    <div className="flex flex-col sm:px-2 text-center items-center justify-center">
      <div className="p-2 sm:p-3 relative scale-90 sm:scale-100">
        <img
          src={imgSrc}
          width={112}
          height={112}
          alt={imgAlt}
          className="relative -top-1 w-[112px] h-[112px]"
        />
        <div className="absolute -top-0.5 -left-1.5 rounded-full bg-white border-2 border-neutral-300 w-[50px] h-[50px] font-display uppercase font-bold text-2xl flex items-center justify-center">
          <span className="relative top-0.5">{badgeCount}</span>
        </div>
      </div>
      <div className="font-bold -mt-1 sm:mt-0 text-xs sm:text-base">
        {badgeLabel}
      </div>
    </div>
  );
};

export default HomeHeaderBadge;
