import React from "react";
import { cn } from "@/app/_utils/ui";

interface HomeHeaderGreetingProps {
  name: string;
}

const randomGreeting = (firstName: string): React.ReactNode => {
  const greetings = [
    <>
      Hey VEGgie,{" "}
      <span className="font-bold text-red-500 whitespace-pre">{firstName}</span>
      !
    </>,
    <>
      Hiya{" "}
      <span className="font-bold text-red-500 whitespace-pre">{firstName}</span>{" "}
      ! Nice to see you!
    </>,
    <>
      What’s up{" "}
      <span className="font-bold text-red-500 whitespace-pre">{firstName}</span>
      ?
    </>,
    <>
      Howdy,{" "}
      <span className="font-bold text-red-500 whitespace-pre">{firstName}</span>{" "}
      ✌️, Veggie!
    </>,
    <>
      Hiya{" "}
      <span className="font-bold text-red-500 whitespace-pre">{firstName}</span>{" "}
      What’s up, dog?
    </>,
    <>
      Hey, cool cat{" "}
      <span className="font-bold text-red-500 whitespace-pre">
        {firstName}!
      </span>
    </>,
    <>
      Meowza, it’s{" "}
      <span className="font-bold text-red-500 whitespace-pre">
        {firstName}!
      </span>
    </>,
  ];

  const day = new Date();
  const dayOfYear = Math.floor(
    (day.getTime() - new Date(day.getFullYear(), 0, 0).getTime()) / 86400000,
  );

  const greetingIndex = (dayOfYear + 1) % greetings.length;

  return greetings[greetingIndex];
};

const HomeHeaderGreeting: React.FC<HomeHeaderGreetingProps> = ({ name }) => {
  return (
    <div className="pb-4 md:pb-0 uppercase font-display font-bold text-3xl md:text-4xl">
      <div
        className={cn("pb-2", !name && "opacity-0")}
        style={{ textWrap: "balance" }}
      >
        {randomGreeting(name)}
      </div>
    </div>
  );
};

export default HomeHeaderGreeting;
