import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import {
  fetchHuddleGroups,
  setHuddleId,
  setHuddleTime,
  setSelectedHuddle,
  startOrJoinHuddle,
} from "../../reducers/huddleSlice";
import { fetchUserHuddles } from "../../reducers/userHuddlesSlice";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/app/_components/ui/dialog";
import LocationSearchInput from "../search/LocationSearchInput";
import Image from "next/image";
import JoinHuddleItem from "./JoinHuddleItem";
import { Button } from "../buttons/button";
import { AppDispatch } from "../../reducers/store";
import { JoinHuddleIcon } from "../graphics/huddle";
import AnimateIn from "../animation/AnimateIn";
import { HuddleGroup } from "@/app/_types";
import { setSelectedLocation } from "../../reducers/locationDashboardSlice";
import { cn } from "@/app/_utils/ui";
import { TOKEN_RESPONSE_CONFIG } from "../../reducers/loginSlice";
import { useRouter } from "next/navigation";

const JoinHuddle = ({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
}) => {
  const [searchCount, setSearchCount] = useState(0);
  const [searchOther, setSearchOther] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const huddleGroups = useSelector(
    (state: RootState) => state.huddleGroups.huddleGroups,
  );
  const { isLoading } = useSelector((state: RootState) => state.huddleGroups);
  const [errorMessage, setErrorMessage] = useState("");

  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );

  const [filteredHuddleGroups, setFilteredHuddleGroups] = useState<
    HuddleGroup[]
  >([]);

  useEffect(() => {
    const newFilteredHuddleGroups = selectedLocation
      ? huddleGroups.filter(
          (huddle) => huddle.Location === selectedLocation.name,
        )
      : huddleGroups;

    setFilteredHuddleGroups(newFilteredHuddleGroups);

    if (newFilteredHuddleGroups.length > 0) {
      setSearchOther(false);
    }
  }, [searchCount, huddleGroups]);

  const handleJoin = async (
    huddleId: string,
    huddleTime: string,
    articleId: string,
    UserJoined: boolean,
  ) => {
    if (accessToken && selectedLocation) {
      const body = {
        huddleId,
        huddleTime: huddleTime.split("+")[0],
        location: selectedLocation?.name,
      };

      dispatch(setHuddleId(huddleId));
      dispatch(setHuddleTime(huddleTime));

      dispatch(
        startOrJoinHuddle({
          articleId,
          body,
          accessToken,
          router,
          type: UserJoined ? "joined" : "join",
        }),
      )
        .unwrap()
        .then(async () => {
          dispatch(setSelectedHuddle(null));
          setOpen(false);
          await dispatch(fetchUserHuddles(accessToken));
        })
        .catch((error: unknown) => {
          if (
            typeof error === "object" &&
            error !== null &&
            "message" in error &&
            error.message === "Unauthorized"
          ) {
            localStorage.removeItem(TOKEN_RESPONSE_CONFIG);
            window.location.reload();
          } else {
            const errorMessage =
              typeof error === "string"
                ? error
                : (error as { message?: string }).message;
            setErrorMessage(
              `Error: ${errorMessage || "An error occurred while starting the huddle. Please try again."}`,
            );
          }
        });
    }
  };

  useEffect(() => {
    if (open) {
      setSearchOther(false);
      dispatch(fetchHuddleGroups(accessToken));
      if (selectedLocation?.name && selectedLocation?.name.startsWith("VQ -")) {
        dispatch(setSelectedLocation({ ...selectedLocation, name: "VQ" }));
      }
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button
            className="items-center h-[45px] w-[250px] md:w-[124px]"
            id="joinHuddle"
          >
            <Image
              width={24}
              height={24}
              alt=""
              src="/assets/images/icons/huddle/join-icon.svg"
              className="mr-2"
            />{" "}
            <span className="pt-0.5">Join</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className={
          searchOther || filteredHuddleGroups.length === 0
            ? "overflow-visible"
            : ""
        }
      >
        <div
          id="joinHuddle"
          className="flex flex-col gap-4 items-center justify-center p-10 max-w-[540px]"
        >
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full bg-red-600">
            <JoinHuddleIcon className="text-white" />
          </div>
          <div className="text-lg -mt-4">Join a Huddle</div>

          {errorMessage && (
            <div className="w-full text-red-600 text-sm">{errorMessage}</div>
          )}

          {isLoading ? (
            <div className="flex items-center justify-center h-28 w-full">
              {/* Placeholder when loading */}
            </div>
          ) : (
            <AnimateIn
              animateFromClassname="opacity-0"
              animateToClassname="opacity-100"
            >
              {filteredHuddleGroups.length === 0 || searchOther ? (
                <div className="w-72 mx-auto text-center">
                  {filteredHuddleGroups.length === 0 && (
                    <div className="font-bold">No recent huddles</div>
                  )}
                  <label className="block text-neutral-700 text-sm mb-1">
                    Search other locations
                  </label>
                  <div className="py-4">
                    <LocationSearchInput
                      initialValue={selectedLocation?.name || ""}
                      autoFocus={searchOther}
                      additionalFilter={(item) => !item.name.includes("VQ -")}
                      onSelectLocation={async (location) => {
                        await dispatch(setSelectedLocation(location));
                        setSearchOther(false);
                        setSearchCount((prevCount) => prevCount + 1);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    "mb-4 w-full flex flex-col gap-8",
                    filteredHuddleGroups.length > 1 && "divide-y",
                  )}
                >
                  {filteredHuddleGroups.map((huddle, index) => (
                    <JoinHuddleItem
                      key={index}
                      huddleItem={huddle}
                      onJoin={() =>
                        handleJoin(
                          huddle.huddleId,
                          huddle.huddleTime,
                          huddle.ArticleId,
                          Boolean(huddle.UserJoined),
                        )
                      }
                    />
                  ))}
                  <div
                    className={cn(
                      "text-center",
                      filteredHuddleGroups.length > 1 && "pt-8",
                    )}
                  >
                    <button
                      onClick={() => setSearchOther(true)}
                      className="underline text-sm text-blue-link"
                    >
                      Search Other Huddles
                    </button>
                  </div>
                </div>
              )}
            </AnimateIn>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default JoinHuddle;
